

























































































import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import orders_filter from '../orders_filter'
import base_service from '../../services/base'
import currency from 'currency.js'
import app_config from '../../application.yml'

export default {
  components:
    'orders-filter': orders_filter

  data: -> {
    headers: [
      {text: 'Placed at', value: 'created_at'}
      {text: 'Dispatch at', value: 'collection_date' }
      # {text: 'Preparation status', value: 'current_preparation_job', sortable: false }
      {text: 'Shipping status', value: 'shipping_status'}
      {text: 'Branch', value: 'supplier_name'}
      {text: 'Ref', value: 'reference'}
      # {text: 'Delivery', value: 'requires_shipping'}
      {text: 'Size', value: 'stokvel'}
      {text: 'Total', value: 'total_in_cents'}
    ],
    options: {
      itemsPerPage: 20
    },
    show_filter: false,
    show_stokvel: false,
    suppliers_options: [],
    loading_suppliers: false,
    stokvel_export_supplier: null,
    rules:
      required: (value) -> Boolean(value) || 'Required.'
  }

  created: ->
    @start_polling()
    @fetch_suppliers()

  beforeDestroy: ->
    @stop_polling()

  watch:
    options:
      handler: -> @fetch_orders() # console.log '[orders_index.watch_options_hander]';
      deep: true

  computed: mapState({
    orders: (state) => state.orders.all
    orders_count: (state) => state.orders.orders_count
    pages: (state) => Math.ceil(state.orders.orders_count / 20)
    loading: (state) => state.orders.loading
    updated_at: (state) => state.orders.updated_at
  })

  methods:
    feature_stokvel: ->
      Boolean(app_config.features.stokvel)

    fetch_orders: ->
      if not @loading
        params = JSON.parse(JSON.stringify(@options))
        @$store.dispatch 'orders/update_pagination', params

    fetch_suppliers: ->
      @loading_suppliers = true
      @$store.dispatch 'suppliers/fetch'
      .then (json) =>
        @suppliers_options = ({ value: supplier.id, text: supplier.name } for supplier in json)
        @loading_suppliers = false
      .catch (error) =>
        @loading_suppliers = false

    format_status: (status) ->
      status.split('_').join(' ') if status

    format_date: (time) ->
      new DateTime.fromISO(time).toFormat('ff')
      # DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_SHORT)

    format_date_with_seconds: (time) -> new DateTime.fromISO(time).toFormat('FF')

    get_job_color: (job_status, cold_count=0) ->
      return 'red' if job_status == 'pending'
      return 'yellow' if job_status == 'issued'
      return 'orange' if job_status == 'stock_short'
      return 'orange' if cold_count >= 1 and job_status in ['pending_payment_at_collection', 'pending_collection']
      return 'green' if job_status in ['pending_payment_at_collection', 'pending_collection', 'pending_delivery']
      return 'blue' if job_status == 'cold'
      return 'green' if job_status in ['delivered', 'collected']

    formatted_job_type: (job_type) ->
      return 'Picker' if job_type == 'supplier_order_pack'
      return 'Cashier' if job_type == 'supplier_order_process'

    number_to_currency: (int) ->
      currency(int, { fromCents: true }).format({ symbol: 'R ' })

    start_polling: ->
      @$store.commit 'orders/polling', true
      @$store.dispatch 'orders/fetch'

    stop_polling: ->
      @$store.commit 'orders/polling', false

    on_row_click: (row) ->
      order = JSON.parse(JSON.stringify(row))
      @$store.dispatch('order/set', order)
      .then => @$router.push "/orders/#{order.id}"

    validate_stokvel_export: (event) ->
      event.preventDefault()
      return if not @$refs.stokvel_export.validate()

      base_service.download '/orders/stokvel_export.pdf', { supplier_id: @stokvel_export_supplier }
      .then (response) ->
        response.blob()
      .then (blob) =>
        url = window.URL.createObjectURL(blob)

        a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        supplier = @suppliers_options.find (s) => s.value == @stokvel_export_supplier
        a.download = "Stokvel stock - #{supplier.text} - #{DateTime.local().toLocaleString(DateTime.DATE_MED)}.pdf"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
}
