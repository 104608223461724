


























































































































































































































































































import api from 'manager/services/base'
import { pick } from 'lodash'
import ImageSelector from 'manager/components/image_selector.vue'
import { Tooltip } from 'bootstrap'

export default {
  components: { ImageSelector }
  props: ['id']

  data: -> {
    combo: {}
    combo_errors: []
    combo_offers: []
    new_combo_item: {}
    loading: false
    publishing: false
    combo_image_loading: false
    offers: []
    new_combo_items: []
  }

  created: ->
    @fetch_combo()
    @fetch_offers()

  mounted: ->
    new Tooltip(@$refs.published_tooltip, { title: 'Offer is visible to customers'})
    new Tooltip(@$refs.top_seller_tooltip, { title: 'Out of stock offer is not orderable, but remains visible to customers'})

  methods: {
    set_image_url: (image_url) ->
      @combo.image_url = image_url

    fetch_combo: ->
      api.combos.show(@id)
      .then (combo) =>
        @combo = combo
      .catch (error) -> console.error('combo_show', error)

    fetch_offers: ->
      api.offers.fetch { sellable_id: @id }
      .then (combo_offers) => @combo_offers = combo_offers
      .finally => @loading_offers = false

    submit_combo: (event) ->
      @loading = true
      sellable = pick(@combo, ['id', 'name', 'barcode', 'unlimited'])
      @toggle_published(@combo.published)
      api.combos.update sellable
      .then (combo_json) =>
        @combo_errors = []
        @toggle_published(@combo.published)
        @combo = omit(combo_json, ['published'])
      .catch (errors) =>
        @combo_errors = errors
        @loading = false

    toggle_published: (published) ->
      @publishing = true
      api.sellables.publish { id: @combo.id, published: published }
      .then (sellable) =>
        @combo.published = sellable.published
        @fetch_offers()
      .catch (error) -> console.error 'toggle_published.catch', error
      .finally => @publishing = false

    submit_combo_item: (event) ->
      @submitting_combo_item = true
      payload = { id: @combo.id, combo_item: JSON.parse(JSON.stringify(@new_combo_item)) }
      api.combos.add_item payload
      .then (combo) => @combo = combo
      .finally => @loading = false

    remove_combo_item: (id) ->
      payload = { id: @combo.id, combo_item: { id: id }}
      api.combos.remove_item payload
      .then (combo) => @combo = combo
      .finally => @loading = false

    fetch_sellable_options: (search, loading) ->
      api.sellables.search search
      .then (sellables) => @new_combo_items = sellables
      .catch (error) -> console.error 'fetch_sellable_options.catch:', error

    toggle_offer_published: (offer) ->
      api.offers.update { id: offer.id, published: offer.published }
      .then (updated_offer) => @fetch_offers()
      .catch (error) -> console.error 'toggle_offer_published.catch', error

    toggle_offer_top_seller: (offer) ->
      api.offers.update { id: offer.id, top_seller: offer.top_seller }
      .then (offer) => @fetch_offers()
      .catch (error) -> console.error 'toggle_offer_top_seller.catch', error
      .finally => @publishing = false

    submit_offer: (offer) ->
      price_in_cents = Number(offer.price) * 100
      console.log 'submit_offer', price_in_cents, offer.top_seller
      api.offers.update { id: offer.id, price_in_cents: price_in_cents, top_seller: offer.top_seller }
      .then (updated_offer) => @fetch_offers()
      .catch (error) -> console.error 'toggle_offer_published.catch', error

    format_date: (date, format) ->
      new Date(date).toLocaleString()
  }
}
